<template>
    <div>
        <div v-if="!loading" class="space">
            <div class="background">
                <img :src="data.background" />
                <div class="space-navigation client-content-padding">
                    <img class="logo" :src="data.logo" />
                    <div class="info">
                        <h5>
                            {{ data.company.companyName }}
                        </h5>
                        <div class="links">
                            <router-link
                                v-for="(link, index) in navigationLinks"
                                :to="{name: link.name, params: link.params}"
                                :class="{link: true, active: $route.name == link.name ? true : false, ['no-focus-outline']: true}"
                                :key="`space-nav-${index}`"
                            >
                                <span>{{ link.label }}</span>
                                <div v-if="index + 1 != navigationLinks.length" class="line"></div>
                            </router-link>
                        </div> 
                    </div>
                    <div class="mobile-links">
                        <router-link
                            v-for="(link, index) in navigationLinks"
                            :class="{link: true, active: link.name == $route.name ? true : false, ['no-focus-outline']: true}"
                            :key="`space-nav-${index}`"
                            :to="{name: link.name}"
                        >
                            <span>{{ link.label }}</span>
                            <div v-if="index + 1 != navigationLinks.length" class="line"></div>
                        </router-link>
                    </div> 
                </div>
            </div>
            <div class="space-content">
                <router-view name="space_content" :labels="labels" :data="data"></router-view>
            </div>
        </div>
        <SvgLoader v-else :loading="true" />
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: 'Space',
    components: {
        SvgLoader: () => import('@/components/Preloaders/SvgLoader')
    },
    data() {
        return {
            loading: true,
            labels: {
                home: 'Home',
                courses: 'Courses',
                authors: 'Authors',
                reviews: 'Reviews',
                featured_courses: 'Featured courses',
                start_course: 'Start course'
            },
            data: {}
        }
    },
    computed: {
        navigationLinks() {
            return [
                {
                    name: 'account-space-home',
                    params: {},
                    label: this.labels.home
                },
                {
                    name: 'account-space-courses',
                    params: {},
                    label: this.labels.courses
                },
                {
                    name: 'account-space-authors',
                    params: {},
                    label: this.labels.authors
                },
                {
                    name: 'account-space-reviews',
                    params: {},
                    label: this.labels.reviews
                }
            ];
        }
    },
    methods: {
        ...mapActions('account', ['getSpace']),
        ...mapActions('space', ['getAccountSpace']),
        ...mapActions('notification', ['addNotification']),
        getSpaceData() {
            this.loading = true;

            this.getAccountSpace().then( res => {
                if (res.success) {
                    console.log('Got res:', res);
                    this.data = res.data;
                }

                if (res.error) {
                    this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                }

                this.loading = false;
            } ).catch( err => console.log( err ) );
        }
    },
    mounted() {
        this.getSpaceData();
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common";
@import "../../../../shared-files/shared.scss";

$space_nav_height: 120px;

.space {

    .background {
        position: relative;
        width: 100%;

        img:not(.logo) {
            width: 100%;
            object-fit: cover;
            height: 40vh;
            object-position: center;
        }

        .space-navigation {
            left: 0;
            bottom: calc(#{$space_nav_height} / 2 * -1); /* Nav height var divided by half to achive navigation being overlaying bottom content by half of it's height */
            margin: auto;
            z-index: 2;
            position: absolute;
            display: flex;
            align-items: center;

            img {
                width: 120px;
                height: $space_nav_height; /* Nav height var */
                object-fit: contain;
                object-position: center;
                border-radius: 5px;
            }

            .info {
                margin-left: 2rem;

                h5 {
                    margin-top: 1rem;
                }

                .links {
                    display: flex;
                    padding-top: 1rem;

                    a {
                        display: flex;

                        span {
                            transition-property: color, background-color;
                            transition-duration: 300ms;
                            transition-timing-function: linear;
                            padding: 5px 15px;
                            border-radius: 5px;
                            background-color: rgba(0,0,0,0.03);
                            color: $focus_color;
                        }

                        div.line {
                            width: 1px;
                            height: 100%;
                            background-color:rgba(0,0,0,0.1);
                            margin-left: 15px;
                            margin-right: 15px;
                        }

                        &:last-child {
                            border-right: 0px;
                        }

                        &.active,
                        &:hover {
                            text-decoration: none;

                            span {
                                background-color: $focus_color;
                                color: $color_over_focus;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            .mobile-links {
                display: none;
            }

            @media (max-width: 767.98px) {
                width: 100%;
                flex-wrap: wrap;
                bottom: calc(310px / 2 * -1);

                &.client-content-padding {
                    padding: 15px;
                }

                .mobile-links {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    margin-top: 20px;
                    background: #f3f3f3;
                    padding: 10px 0px;
                    
                    a {
                        display: flex;

                        span {
                            transition-property: color, background-color;
                            transition-duration: 300ms;
                            transition-timing-function: linear;
                            padding: 5px 15px;
                            border-radius: 5px;
                            background-color: rgba(0,0,0,0.03);
                            color: $focus_color;
                        }

                        div.line {
                            width: 1px;
                            height: 100%;
                            background-color:rgba(0,0,0,0.1);
                            margin-left: 15px;
                            margin-right: 15px;
                        }

                        &:last-child {
                            border-right: 0px;
                        }

                        &.active,
                        &:hover {
                            text-decoration: none;

                            span {
                                background-color: $focus_color;
                                color: $color_over_focus;
                                text-decoration: none;
                            }
                        }

                        &:first-of-type {
                            display: none;
                        }
                    }
                }

                .info {
                    h5 {
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    .links {
                        a {
                            .line {
                                display: none;
                            }
                        }

                        .link:not(:first-of-type) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .space-content {
        min-height: calc(100vh - #{$space_nav_height} - (#{$space_nav_height} / 2 )); /* We want always minmum space so that on any screen overlay nav would never go over footer so it is 100vh minus nav height var and minus half of nav height var (to count it's height) */
        padding-top: 2rem;

        @include client-margin;
    }
}
</style>